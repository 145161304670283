import { UploadOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Input, Space, Upload } from 'antd';
import { isArray } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  ASSET_CATEGORY,
  MAX_LENGTHS,
  PROVIDER_TYPES,
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../../common/constants';
import { fileUpload, formValidatorRules } from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import ProgressBar from '../../../components/ProgressBar';
import history from '../../../historyData';
import useCheckPermission from '../../../hooks/useCheckPermission';
import { SelectPlatForm } from '../../videos/components/FormInputs';
import { CREATE_ASSET, UPDATE_ASSET } from '../graphql/Mutations';
import { GET_ASSET, GET_UPLOAD_SIGNED_URL } from '../graphql/Queries';
import { SelectLanguage } from '../videos/CommonVideoForm';

const { TextArea } = Input;

const CommonTextForm = ({ isModal, setShowModal, setNewAsset }) => {
  const location = useLocation();
  const { textId, isUpdate } = location?.state || {};
  const [form] = Form.useForm();
  const [textData, setTextData] = useState({});
  const [textLoading, setTextLoading] = useState(true);

  const [buttonLoading, setButtonLoading] = useState(false);
  const [textProgress, setTextProgress] = useState(undefined);
  const [fetchText, { data }] = useLazyQuery(GET_ASSET, {
    variables: { where: { id: textId } },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setTextLoading(true);
      setTextData(res?.asset);
      setTextLoading(false);
      form?.setFieldValue('languageCode', res?.asset?.languageCode);
    },
    onError: () => {
      setTextLoading(false);
    }
  });

  useEffect(() => {
    if (textId) {
      fetchText();
    } else {
      setTextLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textId]);

  const [getSignedUrl] = useLazyQuery(GET_UPLOAD_SIGNED_URL);
  const [createAssetMutate] = useMutation(CREATE_ASSET, {
    onError() {
      setButtonLoading(false);
    }
  });
  const [updateAssetMutate] = useMutation(UPDATE_ASSET, {
    onError() {
      setButtonLoading(false);
    }
  });

  const handleCancel = () => {
    if (isModal) {
      setShowModal(false);
      setNewAsset(null);
    } else {
      history?.replace(ROUTES?.TEXT);
    }
  };

  const onFinish = async (values) => {
    setButtonLoading(true);
    const { text, platform } = values;
    try {
      const formData = {
        title: values?.title,
        description: values?.description,
        languageCode: values?.languageCode?.value
      };
      let key = null;
      let contentType = null;
      if (text?.length > 0) {
        setTextProgress(0);
        const textFile = text?.[0]?.originFileObj;
        const fileName = text?.[0]?.name;
        contentType = text?.[0]?.type;
        const getSignedPutUrlResult = await getSignedUrl({
          variables: {
            data: {
              fileName: fileName?.replace(/ /g, '_'),
              contentType,
              assetType: ASSET_CATEGORY?.TEXT,
              platformId: platform?.value
            }
          }
        });
        if (getSignedPutUrlResult?.data?.getAssetUploadSignedUrl) {
          await fileUpload(
            getSignedPutUrlResult?.data?.getAssetUploadSignedUrl?.signedUrl,
            textFile,
            setTextProgress
          );
          key = getSignedPutUrlResult?.data?.getAssetUploadSignedUrl?.key;
        }
      }
      const media = {
        ...formData,
        categoryKey: ASSET_CATEGORY?.TEXT,
        contentType: textId ? data?.asset?.contentType : contentType,
        key
      };
      if (!key) {
        delete media?.key;
      }
      if (isUpdate) {
        const response = await updateAssetMutate({
          variables: {
            where: { id: textId },
            data: {
              ...media
            }
          }
        });
        if (response?.data?.updateAsset) {
          setButtonLoading(false);
          history?.replace(ROUTES?.TEXT);
        }
      } else {
        const response = await createAssetMutate({
          variables: {
            data: {
              ...media,
              platformId: platform?.value
            }
          }
        });
        if (response?.data?.createAsset) {
          setTextProgress(undefined);
          setButtonLoading(false);
          if (isModal) {
            const asset = response?.data?.createAsset?.asset;
            setShowModal(false);
            setNewAsset({ asset: true, data: asset });
          } else {
            history?.replace(ROUTES?.TEXT);
          }
        }
      }
    } catch (error) {
      setTextProgress(undefined);
      setButtonLoading(false);
      return error;
    }
  };

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const initialValues = {
    ...textData,
    languageCode: {
      label: 'English',
      value: 'en'
    },
    platform: textData?.platform
      ? {
          label: textData?.platform?.name,
          value: textData?.platform?.id
        }
      : null
  };

  if (textLoading) {
    return <LoaderComponent />;
  }

  return (
    <>
      <Form
        initialValues={initialValues}
        name="create-asset"
        layout="vertical"
        className="add-edit-form"
        onFinish={onFinish}
        disabled={isViewOnly}
        form={form}
      >
        <Form.Item
          name="title"
          label="Title"
          required
          rules={[
            formValidatorRules?.required('Please enter title!'),
            formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
          ]}
        >
          <Input
            placeholder="Enter title"
            disabled={isViewOnly || buttonLoading}
          />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
        >
          <TextArea
            rows={2}
            placeholder="Enter description"
            disabled={isViewOnly || buttonLoading}
          />
        </Form.Item>
        <Form.Item name="languageCode" label="Language Code">
          <SelectLanguage />
        </Form.Item>
        <Form.Item
          name="platform"
          label="Platform"
          rules={[{ required: true, message: 'Platform is required' }]}
        >
          <SelectPlatForm
            disabled={isUpdate}
            type={PROVIDER_TYPES.STORAGE}
            fullWidth
          />
        </Form.Item>
        <Form.Item
          name="text"
          label="Text Track"
          rules={[{ required: !isUpdate, message: 'Please select text!' }]}
          getValueFromEvent={(e) => {
            if (isArray(e)) {
              return e;
            }
            return e?.fileList;
          }}
          valuePropName="fileList"
        >
          <Upload
            maxCount={1}
            disabled={buttonLoading}
            accept=".vtt"
            beforeUpload={() => false}
          >
            <Space>
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
              <span>(.vtt)</span>
            </Space>
          </Upload>
        </Form.Item>

        {textProgress >= 0 && (
          <Form.Item>
            <ProgressBar progress={textProgress} />
          </Form.Item>
        )}

        <div className="d-flex button-section">
          <Space>
            <Form.Item>
              {isAddEditAllowed && (
                <Button
                  loading={buttonLoading}
                  type="text"
                  htmlType="submit"
                  className="text-btn mr-8"
                  size="middle"
                >
                  Save
                </Button>
              )}
            </Form.Item>
            <Form.Item>
              <Button
                type="text"
                className="text-btn2"
                disabled={buttonLoading}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Form.Item>
          </Space>
        </div>
      </Form>
    </>
  );
};

export default CommonTextForm;
