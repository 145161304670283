import { UploadOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Input, Space, Upload } from 'antd';
import { isArray, isFunction } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  ASSET_CATEGORY,
  MAX_LENGTHS,
  PROVIDER_TYPES,
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../../common/constants';
import { fileUpload, formValidatorRules } from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import ProgressBar from '../../../components/ProgressBar';
import history from '../../../historyData';
import useCheckPermission from '../../../hooks/useCheckPermission';
import { SelectPlatForm } from '../../videos/components/FormInputs';
import { CREATE_ASSET, UPDATE_ASSET } from '../graphql/Mutations';
import { GET_ASSET, GET_UPLOAD_SIGNED_URL } from '../graphql/Queries';
import { SelectLanguage } from '../videos/CommonVideoForm';

const { TextArea } = Input;

const CommonDocumentForm = ({ isModal, setShowModal, setNewAsset }) => {
  const location = useLocation();
  const { documentId, isUpdate } = location?.state || {};
  const [form] = Form.useForm();
  const [documentData, setDocumentData] = useState({});
  const [documentLoading, setDocumentLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [documentProgress, setDocumentProgress] = useState(undefined);

  const [fetchDocument, { data }] = useLazyQuery(GET_ASSET, {
    variables: { where: { id: documentId } },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setDocumentLoading(true);
      setDocumentData(res?.asset);
      setDocumentLoading(false);
      form?.setFieldValue('languageCode', res?.asset?.languageCode);
    },
    onError: () => {
      setDocumentLoading(false);
    }
  });

  useEffect(() => {
    if (documentId) {
      fetchDocument();
    } else {
      setDocumentLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId]);

  const [getSignedUrl] = useLazyQuery(GET_UPLOAD_SIGNED_URL);
  const [createAssetMutate] = useMutation(CREATE_ASSET, {
    onError() {
      setButtonLoading(false);
    }
  });
  const [updateAssetMutate] = useMutation(UPDATE_ASSET, {
    onError() {
      setButtonLoading(false);
    }
  });

  const handleCancel = () => {
    if (isModal) {
      setShowModal(false);
      setNewAsset(null);
    } else {
      history?.replace(ROUTES?.DOCUMENT);
    }
  };

  const onFinish = async (values) => {
    if (isFunction(setNewAsset)) {
      setNewAsset(null);
    }
    setButtonLoading(true);
    const { document, platform } = values;
    try {
      const textData = {
        title: values?.title,
        description: values?.description,
        languageCode: values?.languageCode?.value
      };
      let key = null;
      let contentType = null;
      if (document?.length > 0) {
        setDocumentProgress(0);
        const documentFile = document?.[0]?.originFileObj;
        const fileName = document?.[0]?.name;
        contentType = document?.[0]?.type;
        const getSignedPutUrlResult = await getSignedUrl({
          variables: {
            data: {
              fileName: fileName?.replace(/ /g, '_'),
              contentType,
              assetType: ASSET_CATEGORY?.DOCUMENT,
              platformId: platform?.value
            }
          }
        });
        if (getSignedPutUrlResult?.data?.getAssetUploadSignedUrl) {
          await fileUpload(
            getSignedPutUrlResult?.data?.getAssetUploadSignedUrl?.signedUrl,
            documentFile,
            setDocumentProgress
          );
          key = getSignedPutUrlResult?.data?.getAssetUploadSignedUrl?.key;
        }
      }
      const media = {
        ...textData,
        categoryKey: ASSET_CATEGORY?.DOCUMENT,
        contentType: documentId ? data?.asset?.contentType : contentType,
        key
      };
      if (!key) {
        delete media?.key;
      }
      if (isUpdate) {
        const response = await updateAssetMutate({
          variables: {
            where: { id: documentId },
            data: {
              ...media
            }
          }
        });
        if (response?.data?.updateAsset) {
          setButtonLoading(false);
          history?.replace(ROUTES?.DOCUMENT);
        }
      } else {
        const response = await createAssetMutate({
          variables: {
            data: {
              ...media,
              platformId: platform?.value
            }
          }
        });
        if (response?.data?.createAsset) {
          setDocumentProgress(undefined);
          setButtonLoading(false);
          if (isModal) {
            const asset = response?.data?.createAsset?.asset;
            setShowModal(false);
            setNewAsset({ asset: true, data: asset });
          } else {
            history?.replace(ROUTES?.DOCUMENT);
          }
        }
      }
    } catch (error) {
      setDocumentProgress(undefined);
      setButtonLoading(false);
      return error;
    }
  };

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const initialValues = {
    ...documentData,
    languageCode: {
      label: 'English',
      value: 'en'
    },
    platform: documentData?.platform
      ? {
          label: documentData?.platform?.name,
          value: documentData?.platform?.id
        }
      : null
  };

  if (documentLoading) {
    return <LoaderComponent />;
  }

  return (
    <>
      <Form
        initialValues={initialValues}
        name="create-asset"
        layout="vertical"
        className="add-edit-form"
        onFinish={onFinish}
        disabled={isViewOnly}
        form={form}
      >
        <Form.Item
          name="title"
          label="Title"
          required
          rules={[
            formValidatorRules?.required('Please enter title!'),
            formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
          ]}
        >
          <Input
            placeholder="Enter title"
            disabled={isViewOnly || buttonLoading}
          />
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
        >
          <TextArea
            rows={2}
            placeholder="Enter description"
            disabled={isViewOnly || buttonLoading}
          />
        </Form.Item>
        <Form.Item name="languageCode" label="Language Code">
          <SelectLanguage />
        </Form.Item>
        <Form.Item
          name="platform"
          label="Platform"
          rules={[{ required: true, message: 'Platform is required' }]}
        >
          <SelectPlatForm
            disabled={isViewOnly || isUpdate}
            type={PROVIDER_TYPES.STORAGE}
            fullWidth
          />
        </Form.Item>

        <Form.Item
          name="document"
          label="Document"
          rules={[{ required: !isUpdate, message: 'Please select document!' }]}
          getValueFromEvent={(e) => {
            if (isArray(e)) {
              return e;
            }
            return e?.fileList;
          }}
          valuePropName="fileList"
        >
          <Upload
            maxCount={1}
            disabled={isViewOnly || buttonLoading}
            accept=".doc,.docx,.pdf,.csv,.txt,.xlsx,.woff,.woff2,.ttf"
            beforeUpload={() => false}
          >
            <Space>
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
              <span>
                (.doc, .docx, .pdf, .csv, .txt, .xlsx, .woff, .woff2, .ttf)
              </span>
            </Space>
          </Upload>
        </Form.Item>

        {documentProgress >= 0 && (
          <Form.Item>
            <ProgressBar progress={documentProgress} />
          </Form.Item>
        )}

        <div className="d-flex button-section">
          <Space>
            <Form.Item>
              <Button
                loading={buttonLoading}
                type="text"
                htmlType="submit"
                className="text-btn mr-8"
                size="middle"
              >
                Save
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="text"
                className="text-btn2"
                disabled={buttonLoading}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Form.Item>
          </Space>
        </div>
      </Form>
    </>
  );
};

export default CommonDocumentForm;
