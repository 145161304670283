import { DeleteOutlined, EditOutlined, EyeFilled } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Tag, Tooltip } from 'antd';
import moment from 'moment';
import { PencilSimpleLine, Trash } from 'phosphor-react';
import React, { useRef } from 'react';
import DocumentPlaceholder from '../../../assets/images/docPlaceholder.png';
import {
  ASSET_CATEGORY,
  MODULES,
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../../common/constants';
import { modalContext } from '../../../components/AppComponentContainer';
import FilterBar, { useFilterBar } from '../../../components/FilterBar';
import PageHeader from '../../../components/PageHeader';
import PageList from '../../../components/PageList';
import useCheckPermission from '../../../hooks/useCheckPermission';
import CustomCard from '../../component/card/Cscard';
import { DELETE_ASSET } from '../graphql/Mutations';
import { GET_ASSETS } from '../graphql/Queries';

const variablesSelector = ({
  limit,
  offset,
  search,
  sortField,
  sortOrder
}) => ({
  filter: {
    limit,
    skip: offset,
    search,
    categoryKey: ASSET_CATEGORY?.DOCUMENT
  },
  sort: {
    sortOn: sortField,
    sortBy: sortOrder
  }
});

const dataSelector = ({ assets }) => ({
  data: assets?.assets || [],
  count: assets?.count || 0
});

const columns = ({
  isPlatformAllowed,
  isDeleteAllowed,
  handlePreview,
  handleEdit,
  handleDelete,
  isViewOnly
}) => [
  {
    title: 'Image',
    dataIndex: 'url',
    key: 'url',
    editable: true,
    width: '12%',
    render: (text, record) => (
      <img
        onClick={() => handlePreview(record)}
        className="group-img-table pointer"
        src={DocumentPlaceholder}
        alt={record?.title ?? ''}
      />
    )
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    width: '22%'
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    width: '22%'
  },
  {
    title: 'Language',
    dataIndex: 'languageCode',
    key: 'languageCode',
    width: '22%',
    render: (value) =>
      value && (
        <Tag className="m-0 custom-tag" color="magenta-inverse">
          {value}
        </Tag>
      )
  },
  ...(isPlatformAllowed || isDeleteAllowed
    ? [
        {
          title: 'Action',
          dataIndex: 'operation',
          width: '12%',
          render: (text, record) => (
            <div>
              {isPlatformAllowed && (
                <>
                  <Tooltip title={`${isViewOnly ? 'View' : 'Edit'} Document`}>
                    {isViewOnly ? (
                      <EyeFilled
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEdit(record?.id);
                        }}
                      />
                    ) : (
                      <EditOutlined
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEdit(record?.id);
                        }}
                      />
                    )}
                  </Tooltip>
                </>
              )}
              {isDeleteAllowed && (
                <span onClick={(e) => e.stopPropagation()}>
                  <Tooltip title="Delete document">
                    <DeleteOutlined onClick={() => handleDelete(record?.id)} />
                  </Tooltip>
                </span>
              )}
            </div>
          )
        }
      ]
    : [])
];

function Document({ history }) {
  const ref = useRef();
  const filterProps = useFilterBar();
  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const isDeleteAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.DELETE]
    }
  ]);
  const isPlatformAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.PLATFORM_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.VIEW,
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const [deleteDocument] = useMutation(DELETE_ASSET, {
    onError() {}
  });

  const handleShowModal = () => {
    history?.push(`${ROUTES?.DOCUMENT}/add`, { isUpdate: false });
  };

  const handleEdit = (id) => {
    history.push(`${ROUTES?.DOCUMENT}/${id}/edit`, {
      isUpdate: true,
      documentId: id
    });
  };

  const handlePreview = (item) => {
    if (ref.current.showPreview) {
      ref.current.showPreview({
        title: item.title,
        url: item.url,
        type: ASSET_CATEGORY.DOCUMENT
      });
    }
  };

  const handleDelete = async (id) => {
    modalContext?.confirm({
      title: 'Are you sure, you want to delete this document?',
      centered: true,
      okText: 'Yes',
      cancelText: 'No',
      okType: 'primary',
      async onOk() {
        deleteDocument({
          variables: { where: { id } }
        })
          .then(({ errors }) => {
            if (ref.current.removeItem && !errors) {
              ref.current.removeItem(id);
            }
          })
          .catch();
      }
    });
  };

  return (
    <>
      <PageHeader
        menu={MODULES?.ASSETS}
        handleShowModal={handleShowModal}
        isAddEditAllowed={isPlatformAllowed && isAddEditAllowed}
      />
      <FilterBar
        {...filterProps}
        SearchProps={{ placeholder: 'Search Document' }}
      />
      <PageList
        ref={ref}
        filters={filterProps.filters}
        listMode={filterProps.listMode}
        query={GET_ASSETS}
        variablesSelector={variablesSelector}
        dataSelector={dataSelector}
        GridProps={{
          renderItem: (item) => (
            <CustomCard
              onClick={() => {
                handlePreview(item);
              }}
              alt={item?.title}
              src={DocumentPlaceholder}
              title={moment(item?.createdAt).format('ll')}
              heading={item?.title}
              description={item?.description}
              languageCode={item?.languageCode}
              primaryOnClick={(e) => {
                e?.stopPropagation();
                e?.preventDefault();
                handleEdit(item?.id);
              }}
              secondaryOnClick={(e) => {
                e?.stopPropagation();
                e?.preventDefault();
                handleDelete(item?.id);
              }}
              primary={
                isPlatformAllowed &&
                (isViewOnly ? (
                  <>
                    <EyeFilled size={16} />
                    View
                  </>
                ) : (
                  <>
                    <PencilSimpleLine size={16} />
                    Edit
                  </>
                ))
              }
              secondary={
                isDeleteAllowed && (
                  <>
                    <Trash size={16} />
                    Delete
                  </>
                )
              }
            />
          )
        }}
        TableProps={{
          columns: (props) =>
            columns({
              ...props,
              isPlatformAllowed,
              isAddEditAllowed,
              isDeleteAllowed,
              handlePreview,
              handleEdit,
              handleDelete,
              isViewOnly
            })?.filter((item) => item !== false)
        }}
      />
    </>
  );
}

export default Document;
